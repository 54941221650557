<template>
    <div class="form-group">
        <label v-if="showLabel" class="form-group__label">{{ placeholder }}</label>
        <date-picker
            :lang="lang"
            class="form-control form-group__input"
            v-model="datepickerValue"
            :type="datepickerType"
            :editable="false"
            :format="format"
            :valueType="valueType"
            :disabled="disabled"
            :range="range"
            :clearable="clearable"
            :placeholder="showLabel ? '' : placeholder"
            :show-second="false"
            :class="{'error' : error.length}"
            :show-time-header="false"
            :not-before="time3"
            @change="emitDataValue"
        />
        <span v-if="error" class="form-group__error">{{ Array.isArray(error) ? error[0] : error }}</span>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
    name: "dataPickerComponent",
    components: {
        DatePicker
    },
    props: {
        value: {
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        range: {
            type: Boolean,
            required: false,
            default: false
        },
        format:{
          type: String,
          default: 'YYYY-MM-DD'
        },
        valueType: {
            type: String,
            default: 'date'
        },
        icon: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        routeValue: {
            required: false
        },
        size: {
            type: String,
            required: false,
            default: 'large' // medium, large, small
        },
        datepickerType: {
            type: String,
            required: false,
            default: 'date'
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            required: false,
            default: false
        },
        error: {
            required: false,
            default: false
        },
        isLogs: {
            required: false,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            time3: new Date,
            lang: {
                days: [this.$t('Sun'), this.$t('Mon'), this.$t('Tue'), this.$t('Wed'), this.$t('Thu'), this.$t('Fri'), this.$t('Sat'),],
                months: [this.$t('January'), this.$t('February'), this.$t('March'), this.$t('April'), this.$t('May'), this.$t('June'), this.$t('July'), this.$t('August'), this.$t('September'), this.$t('October'), this.$t('November'), this.$t('December')],
                weekdays: [this.$t('Sunday'), this.$t('Monday'), this.$t('Tuesday'), this.$t('Wednesday'), this.$t('Thursday'), this.$t('Friday'), this.$t('Saturday')],
                formatLocale: {
                    firstDayOfWeek: 2,
                },
                clearable: false,
                monthBeforeYear: false,
                placeholder: {
                    date: this.$t('Select date'),
                    dateRange: this.$t('Select date range')
                }
            },

            datepickerValue: null,

        }
    },
    methods: {
        emitDataValue(){
            if (!this.range) {
                if (this.datepickerValue){
                    this.$emit('input', this.moment(this.datepickerValue).format('YYYY/MM/DD HH:mm'));
                } else {
                    this.clearDate()
                }
            }
            else {
                this.$emit('input', this.datepickerValue);
            }
        },
        clearDate(){
            this.$emit('clearDate');
            this.datepickerValue = null
        }
    },
    watch: {
        value() {
            this.datepickerValue = this.range ? this.value : new Date(this.value);
        },
    },
    computed: {
        iconCode() {
            if(this.type === 'datepicker' && !this.icon) {
                return 'calendar';
            }
            return this.icon ? this.icon : null;
        }
    },
    created() {
        this.datepickerValue = this.value;
    },

    mounted() {
        if (this.routeValue) {
            if (this.$route.query.date){
                this.datepickerValue = new Date(this.$route.query.date);
            }
        }
        if (this.isLogs) {
            this.time3 = false
        }
    }
}
</script>

<style lang="scss" scoped>

.form-group {
    position: relative;



    &__label {
        margin-bottom: 8px;
        color: $color_gray;
    }

    &__input {
        border: solid 1px #EEF3FD;
        border-radius: 2px;
        color: $color_black;
        padding: 0;
        font-size: 15px;
        font-family: $ff-primary;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: $color_black;
        }

        &.is-invalid {
            border-color: $color_red;
        }

        &[disabled] {
            color: $color_gray;
            cursor: initial;
            border: solid 1px $color_light;

            &::placeholder {
                color: $color_gray;
            }
        }
    }

    &__error {
        //font-size: 13px;
        //color: $color_red;
        //position: absolute;
        //right: 0;
        //bottom: -18px;
        font-size: 13px;
        color: #FF496D;
        position: absolute;
        right: 0;
        bottom: auto;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.btn-clear-date{
    background-color: white;
    border: none;
    padding: 0;
    position: absolute;
    right: -21px;
    top: 10px;
}

::v-deep{
    .vti__input{
        background-color: rgba(237, 243, 255, 0.4)!important;
    }
    .mx-input{
        background-color: #F8FAFF;
    }
}
</style>

<style lang="scss">

    .form-group__input {
        .mx-input {
            padding: 13px 15px;
            line-height: initial;
            box-shadow: none;
            border: none;
            height: auto;
            padding-right: 40px;
        }

        .mx-icon-calendar, .mx-icon-clear {
            right: 15px;
            line-height: initial;
            color: $color_blue
        }
    }


</style>
